import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";

interface SEOProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: any;
  image?: string;
}

const SEO = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  image = "",
}: SEOProps): ReactElement => {
  const { href, host, protocol } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: href,
        },
        {
          property: `og:image`,
          content: image ? `${protocol}://${host}${image}` : ``,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
