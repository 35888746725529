import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SocialMediaLinks from "../social-media-links/social-media-links";

const ListContainer = styled.div`
  min-width: 200px;
  a {
    font-family: "Bebas Neue";
    letter-spacing: 0.1rem;
    color: #565656;
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SideDrawer = ({ links }) => {
  const [drawerState, setDrawerState] = useState(false);
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  return (
    <>
      <IconButton aria-label="Toggle sidebar navigation" onClick={toggleDrawer}>
        <MenuIcon fontSize="large" />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={drawerState}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
      >
        <ListContainer>
          <List>
            {links.map((link) => (
              <ListItem button key={link.title}>
                <ListItemIcon>
                  <link.icon />
                </ListItemIcon>
                <ListItemText>
                  <Link to={link.route}>{link.title}</Link>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </ListContainer>
        <SocialLinksContainer>
          <SocialMediaLinks />
        </SocialLinksContainer>
      </SwipeableDrawer>
    </>
  );
};

export default SideDrawer;
