import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";
import { IconButton } from "@mui/material";

const links = [
  {
    title: "Twitter",
    href: "https://twitter.com/BenPearey",
    icon: TwitterIcon,
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/benpearey",
    icon: InstagramIcon,
  },
];

const SocialMediaLinks = () => {
  return (
    <>
      {links.map((link) => (
        <a
          key={link.title}
          href={link.href}
          title={link.title}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton aria-label={link.title}>
            <link.icon size="small" />
          </IconButton>
        </a>
      ))}
    </>
  );
};

export default SocialMediaLinks;
