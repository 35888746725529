import React from "react";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import CookieConsent from "react-cookie-consent";

import "./layout.css";
import Header from "../header/header";
import Footer from "../footer/footer";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "georgia", "serif", "sans-serif"].join(","),
  },
});

const defaultMaxWidth = "960px";

export default function Layout({ children, maxWidth = defaultMaxWidth }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div
          style={{
            margin: `auto`,
            padding: `0 40px`,
            position: "relative",
            maxWidth,
            minHeight: "70vh",
          }}
        >
          {children}
        </div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          declineButtonText="Decline"
          enableDeclineButton
          cookieName="gatsby-gdpr-google-analytics"
        >
          Cookies are used on this site to help improve your experience. Is that
          ok?
        </CookieConsent>
      </ThemeProvider>
    </>
  );
}
