import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";
import HomeIcon from "@mui/icons-material/Home";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Hidden from "@mui/material/Hidden";
import SideDrawer from "./side-drawer";
import SocialMediaLinks from "../social-media-links/social-media-links";

const StyledHeader = styled.header`
  background-color: #f9fafb;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin: auto;
  max-width: 1440px;
`;

const SiteTitle = styled.div``;

const LogoTitle = styled.div`
  font-family: "Bebas Neue";
  font-size: 2.25rem;
  line-height: 1.5rem;
  margin-top: 6px;
`;

const LogoSubtitle = styled.div`
  font-family: "Poppins";
  color: #676767;
  letter-spacing: 0.05em;
  line-height: 1rem;
  text-transform: uppercase;
`;

const StyledNav = styled.nav`
  font-family: "Bebas Neue";
  font-size: 1.2rem;
`;

const siteLinks = [
  { title: "Home", route: "/", icon: HomeIcon },
  { title: "Gallery", route: "/gallery", icon: PhotoLibraryIcon },
  { title: "Contact", route: "/contact", icon: ContactMailIcon },
];

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            logoTitle
            logoSubtitle
          }
        }
      }
    `
  );
  return (
    <StyledHeader>
      <HeaderWrapper>
        <Link to="/" style={{ display: "flex", flexDirection: "row" }}>
          <SiteTitle>
            <LogoTitle>{site.siteMetadata.logoTitle}</LogoTitle>
            <LogoSubtitle>{site.siteMetadata.logoSubtitle}</LogoSubtitle>
          </SiteTitle>
        </Link>

        <StyledNav>
          <Hidden smDown>
            {siteLinks.map((link) => (
              <Link
                key={link.title}
                style={{ alignSelf: "center", marginRight: "1.5rem" }}
                to={link.route}
              >
                {link.title}
              </Link>
            ))}
          </Hidden>
          <Hidden xsDown>
            <SocialMediaLinks />
          </Hidden>
          <Hidden mdUp>
            <SideDrawer links={siteLinks} />
          </Hidden>
        </StyledNav>
      </HeaderWrapper>
    </StyledHeader>
  );
};

export default Header;
