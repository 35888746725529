import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import SocialMediaLinks from "../social-media-links/social-media-links";

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
`;

const UpperFooter = styled.div`
  width: 100%;
  background-color: #f9fafb;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;
const LowerFooter = styled.div`
  width: 100%;
  background-color: #f3f4f6;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <StyledFooter>
      <UpperFooter>
        <SocialMediaLinks></SocialMediaLinks>
      </UpperFooter>
      <LowerFooter>
        <p>
          &copy; Copyright {new Date().getFullYear()} {site.siteMetadata.title}
        </p>
      </LowerFooter>
    </StyledFooter>
  );
};

export default Footer;
